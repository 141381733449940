import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.adsState || initialState;

export const adsSelector = createSelector(selectDomain, (state) => state.ads);

export const adsDropdownListSelector = createSelector(selectDomain, (state) => state.adsList);

export const adsStateSelector = createSelector(selectDomain, (state) => state);

export const deletingAdsSelector = createSelector(selectDomain, (state) => state.deletingAd);

export const updatingAdsSelector = createSelector(selectDomain, (state) => state.updatingAd);

export const uploadingFileSelector = createSelector(selectDomain, (state) => state.uploadingFile);

export const uploadedFileUrls = createSelector(selectDomain, (state) => state.fileUrls);

export const uploadedSingleFileUrl = createSelector(selectDomain, (state) => state.singleFileUrl);

export const singleAdsSelector = createSelector(selectDomain, (state) => state.singleAd);
