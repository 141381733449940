import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';
import { createUserService, deleteUserService, getAllUserService, getUserById, updateSingleUser } from 'utils/services/user.service';
import { call, put, select } from 'redux-saga/effects';
import { userStateSelector } from './selector';
import { UserReducerAction } from './slice';

const saga = createSliceSaga({
    name: 'user-state',
    caseSagas: {
        *getAllUsers() {
            try {
                // yield put(UserReducerAction.updateUserPending());
                const { limit, page, search } = yield select(userStateSelector);
                const skip = page - 1;
                const response: AxiosResponse = yield call(getAllUserService, {
                    limit,
                    skip,
                    search
                });
                if (response?.status === 200) {
                    // yield put(UserReducerAction.updateUserSuccess(response.data));
                    const updatedUserList = {
                        data: [...response?.data?.data],
                        total: response?.data?.total
                    };
                    yield put(UserReducerAction.setUsersList(updatedUserList));
                } else {
                    console.log('No Response');
                    // yield put(UserReducerAction.updateUserFailure('Failed to Get Users.'));
                }
            } catch (error) {
                console.log('error', error);
                yield put(UserReducerAction.updateUserFailure('Failed to Get Users.'));
            }
        },
        *deleteUser(action: { payload: string }) {
            try {
                yield put(UserReducerAction.deleteUserPending());
                const userId = action.payload;
                yield call(deleteUserService, { userId });
                yield put(UserSagaActions.getAllUsers());
            } catch (error) {
                console.error('error', error);
                yield put(UserReducerAction.deleteUserFailure());
            } finally {
                yield put(UserReducerAction.deleteUserSuccess());
            }
        },
        *singleUser(action: { payload: string }) {
            try {
                const userId = action.payload;
                const response: AxiosResponse = yield call(getUserById, { userId });

                if (response?.status === 200) {
                    const singleUserData = {
                        data: response?.data?.data
                    };
                    yield put(UserReducerAction.setSingleUser(singleUserData));
                }
            } catch (error) {
                console.error('error', error);
            }
        },
        *createUser(action: { payload: { values: any } }) {
            try {
                yield put(UserReducerAction.updateUserPending());

                const { values } = action.payload;
                const response: AxiosResponse = yield call(createUserService, values);

                if (response?.status === 201 || response?.status === 201) {
                    yield put(UserReducerAction.updateUserSuccess(response.data));
                } else {
                    const { message }: any = response;
                    yield put(UserReducerAction.updateUserFailure(message || 'Failed to Create user.'));
                }
            } catch (error) {
                yield put(UserReducerAction.updateUserFailure(error.message || 'Error creating user.'));
            }
        },
        *updateUser(action: { payload: { id: string; values: any } }) {
            try {
                yield put(UserReducerAction.updateUserPending());

                const { id, values } = action.payload;
                const response: AxiosResponse = yield call(updateSingleUser, id, values);

                if (response?.status === 200) {
                    yield put(UserReducerAction.updateUserSuccess(response.data));
                    yield put(UserSagaActions.singleUser(id));
                } else {
                    const { message }: any = response;

                    yield put(UserReducerAction.updateUserFailure(message || 'Failed to update user.'));
                }
            } catch (error) {
                console.error('error', error.message);
                yield put(UserReducerAction.updateUserFailure('Error updating user.'));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: UserSagaActions, saga: UserSaga, name: UserSagaName } = saga;
