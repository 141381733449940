import axiosServices from 'utils/axios';
import { adsParams } from 'utils/types/ads.types';

export const getAllAdsService = async ({ limit, skip, search, userId, pageId }: adsParams) => {
    try {
        const response = await axiosServices.post(`/ad/list`, {
            limit,
            skip,
            search,
            filters: {
                userId,
                pageId: pageId ? [pageId] : []
            }
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const deleteAdService = async (params: any) => {
    try {
        const response = await axiosServices.delete(`/ad/${params.adId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getAdById = async (params: any) => {
    if (!params.adId) return;
    try {
        const response = await axiosServices.get(`/ad/${params.adId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createAdService = async (values: any) => {
    try {
        const response = await axiosServices.post(`/ad/admin`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const uploadingFileService = async (values: any) => {
    console.log('uploadingValues', values);
    try {
        const response = await axiosServices.post(`/ad/add-media/multiple-to-s3`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const uploadingSingleFileService = async (values: any) => {
    console.log('uploadingSingleFile', values);
    try {
        const response = await axiosServices.post(`/ad/add-media/to-s3`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateSingleAd = async (id: string, values: any) => {
    try {
        const response = await axiosServices.put(`/ad/admin/${id}`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const getAllAdsDropdownList = async () => {
    try {
        const response = await axiosServices.get(`/ad/dropdownList`);
        return response;
    } catch (error) {
        return error;
    }
};
