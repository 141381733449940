import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';

import { call, put, select } from 'redux-saga/effects';
import { teamStateSelector } from './selector';
import {
    createSingleTeam,
    deleteTeamService,
    getAllTeamsService,
    getAllUsersService,
    getTeamById,
    updateSingleTeam
} from 'utils/services/teams.service';
import { TeamReducerAction } from './slice';

const saga = createSliceSaga({
    name: 'team-state',
    caseSagas: {
        *getAllTeam() {
            try {
                // yield put(TeamReducerAction.updateTeamPending());
                const { limit, page, search } = yield select(teamStateSelector);
                const skip = page - 1;
                const response: AxiosResponse = yield call(getAllTeamsService, {
                    limit,
                    skip,
                    search
                });
                if (response?.status === 200) {
                    // yield put(TeamReducerAction.updateTeamSuccess(response.data));

                    const updatedTeamList = {
                        data: [...response?.data?.data],
                        total: response?.data?.total
                    };
                    yield put(TeamReducerAction.setTeamsList(updatedTeamList));
                } else {
                    console.log('no response');
                    // yield put(TeamReducerAction.updateTeamFailure('Failed to get Team.'));
                }
            } catch (error) {
                console.log('error', error);
                // yield put(TeamReducerAction.updateTeamFailure('Error Getting Team.'));
            }
        },
        *deleteTeam(action: { payload: string }) {
            try {
                yield put(TeamReducerAction.deleteTeamPending());
                const teamId = action.payload;
                yield call(deleteTeamService, { teamId });
                yield put(TeamSagaActions.getAllTeam());
            } catch (error) {
                console.error('Error Deleting Team:', error);
                yield put(TeamReducerAction.deleteTeamFailure());
            } finally {
                yield put(TeamReducerAction.deleteTeamSuccess());
            }
        },
        *singleTeam(action: { payload: string }) {
            try {
                yield put(TeamReducerAction.updateTeamPending());
                const teamId = action.payload;

                if (teamId) {
                    const response: AxiosResponse = yield call(getTeamById, { teamId });
                    if (response?.status === 200) {
                        yield put(TeamReducerAction.updateTeamSuccess(response.data));

                        const singleTeamData = {
                            data: response?.data || {}
                        };

                        yield put(TeamReducerAction.setSingleTeam(singleTeamData));
                    }
                } else {
                    yield put(TeamReducerAction.setSingleTeam({}));
                    yield put(TeamReducerAction.updateTeamFailure('Error Getting Team'));
                }
            } catch (error) {
                console.error('Error Single Team:', error);
                yield put(TeamReducerAction.updateTeamFailure('Error Getting Team.'));
            }
        },
        *createTeam(action: { payload: { values: any } }) {
            try {
                yield put(TeamReducerAction.updateTeamPending());

                const { values } = action.payload;
                const response: AxiosResponse = yield call(createSingleTeam, values);

                if (response?.status === 200) {
                    yield put(TeamReducerAction.updateTeamSuccess(response.data));
                } else {
                    yield put(TeamReducerAction.updateTeamFailure('Failed to Create Team.'));
                }
            } catch (error) {
                console.error('Error Updating Team:', error);
                yield put(TeamReducerAction.updateTeamFailure(error.message || 'Error Creating Team.'));
            }
        },
        *updateTeam(action: { payload: { id: string; values: any } }) {
            try {
                yield put(TeamReducerAction.updateTeamPending());

                const { id, values } = action.payload;
                const response: AxiosResponse = yield call(updateSingleTeam, id, values);

                if (response?.status === 200) {
                    yield put(TeamReducerAction.updateTeamSuccess(response.data));
                    yield put(TeamSagaActions.singleTeam(id));
                } else {
                    yield put(TeamReducerAction.updateTeamFailure('Failed to update Team.'));
                }
            } catch (error) {
                console.error('Error Updating Team:', error);
                yield put(TeamReducerAction.updateTeamFailure(error.message || 'Error updating Team.'));
            }
        },
        *getAllUsers() {
            try {
                const response: AxiosResponse = yield call(getAllUsersService);
                if (response?.status === 200) {
                    console.log('response', response);
                    const updatedUsersList = {
                        data: [...response?.data?.data]
                    };
                    console.log('updatedUsersList', updatedUsersList);
                    yield put(TeamReducerAction.setUsersList(updatedUsersList));
                }
            } catch (error) {
                console.log('error', error);
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: TeamSagaActions, saga: TeamSaga, name: TeamSagaName } = saga;
