import { createSlice } from '@reduxjs/toolkit';
import { UserInitialStateType } from './types';

export const initialState: UserInitialStateType = {
    error: null,
    users: {},
    limit: 10,
    page: 1,
    search: '',
    singleUser: {},
    deletingUser: false,
    updatingUser: false
};

const user = createSlice({
    name: 'userState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setUsersList(state, action) {
            state.users = action.payload;
        },

        setCurrentPage(state, action) {
            state.page = action.payload;
        },

        setLimit(state, action) {
            state.limit = action.payload;
        },

        setSearch(state, action) {
            state.search = action.payload;
        },

        setSingleUser(state, action) {
            state.singleUser = action.payload;
        },

        deleteUserPending(state) {
            state.deletingUser = true;
        },
        deleteUserSuccess(state) {
            state.deletingUser = false;
        },
        deleteUserFailure(state) {
            state.deletingUser = false;
        },

        updateUserPending(state) {
            state.updatingUser = true;
            state.error = null;
        },
        updateUserSuccess(state, action) {
            state.updatingUser = false;
        },
        updateUserFailure(state, action) {
            state.error = action.payload;
            state.updatingUser = false;
        }
    }
});

export const { actions: UserReducerAction, name: UserReducerName, reducer: UserReducer } = user;
