// third-party
import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

// assets
import {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconDeviceDesktopDollar,
    IconAd2,
    IconBrandBumble,
    IconUsersGroup,
    IconChecklist,
    IconClipboardCopy,
    IconFolders,
    IconClipboardText
} from '@tabler/icons-react';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconDeviceDesktopDollar,
    IconAd2,
    IconBrandBumble,
    IconUsersGroup,
    IconChecklist,
    IconClipboardCopy,
    IconFolders,
    IconClipboardText
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

// const application: NavItemType = {
//     id: 'application',
//     title: <FormattedMessage id="application" />,
//     icon: icons.IconApps,
//     type: 'group',
//     children: [
//         {
//             id: 'users',
//             title: <FormattedMessage id="users" />,
//             type: 'collapse',
//             icon: icons.IconUserCheck,
//             children: [
//                 {
//                     id: 'posts',
//                     title: <FormattedMessage id="social-profile" />,
//                     type: 'item',
//                     url: '/apps/user/social-profile/posts'
//                 },
//                 {
//                     id: 'account-profile',
//                     title: <FormattedMessage id="account-profile" />,
//                     type: 'collapse',
//                     children: [
//                         {
//                             id: 'profile1',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="profile" /> 01
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/account-profile/profile1'
//                         },
//                         {
//                             id: 'profile2',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="profile" /> 02
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/account-profile/profile2'
//                         },
//                         {
//                             id: 'profile3',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="profile" /> 03
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/account-profile/profile3'
//                         }
//                     ]
//                 },
//                 {
//                     id: 'user-card',
//                     title: <FormattedMessage id="cards" />,
//                     type: 'collapse',
//                     children: [
//                         {
//                             id: 'card1',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="style" /> 01
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/card/card1'
//                         },
//                         {
//                             id: 'card2',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="style" /> 02
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/card/card2'
//                         },
//                         {
//                             id: 'card3',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="style" /> 03
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/card/card3'
//                         }
//                     ]
//                 },
//                 {
//                     id: 'user-list',
//                     title: <FormattedMessage id="list" />,
//                     type: 'collapse',
//                     children: [
//                         {
//                             id: 'list1',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="style" /> 01
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/list/list1'
//                         },
//                         {
//                             id: 'list2',
//                             title: (
//                                 <>
//                                     <FormattedMessage id="style" /> 02
//                                 </>
//                             ),
//                             type: 'item',
//                             url: '/apps/user/list/list2'
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             id: 'customer',
//             title: <FormattedMessage id="customer" />,
//             type: 'collapse',
//             icon: icons.IconBasket,
//             children: [
//                 {
//                     id: 'customer-list',
//                     title: <FormattedMessage id="customer-list" />,
//                     type: 'item',
//                     url: '/apps/customer/customer-list',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'order-list',
//                     title: <FormattedMessage id="order-list" />,
//                     type: 'item',
//                     url: '/apps/customer/order-list',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'create-invoice',
//                     title: <FormattedMessage id="create-invoice" />,
//                     type: 'item',
//                     url: '/apps/customer/create-invoice',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'order-details',
//                     title: <FormattedMessage id="order-details" />,
//                     type: 'item',
//                     url: '/apps/customer/order-details'
//                 },
//                 {
//                     id: 'product',
//                     title: <FormattedMessage id="product" />,
//                     type: 'item',
//                     url: '/apps/customer/product',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'product-review',
//                     title: <FormattedMessage id="product-review" />,
//                     type: 'item',
//                     url: '/apps/customer/product-review',
//                     breadcrumbs: false
//                 }
//             ]
//         },
//         {
//             id: 'chat',
//             title: <FormattedMessage id="chat" />,
//             type: 'item',
//             icon: icons.IconMessages,
//             url: '/apps/chat'
//         },
//         {
//             id: 'kanban',
//             title: 'Kanban',
//             type: 'item',
//             icon: icons.IconLayoutKanban,
//             url: '/apps/kanban/board'
//         },
//         {
//             id: 'mail',
//             title: <FormattedMessage id="mail" />,
//             type: 'item',
//             icon: icons.IconMail,
//             url: '/apps/mail'
//         },
//         {
//             id: 'calendar',
//             title: <FormattedMessage id="calendar" />,
//             type: 'item',
//             url: '/apps/calendar',
//             icon: icons.IconCalendar,
//             breadcrumbs: false
//         },
//         {
//             id: 'contact',
//             title: <FormattedMessage id="contact" />,
//             type: 'collapse',
//             icon: icons.IconNfc,
//             children: [
//                 {
//                     id: 'c-card',
//                     title: <FormattedMessage id="cards" />,
//                     type: 'item',
//                     url: '/apps/contact/c-card',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'c-list',
//                     title: <FormattedMessage id="list" />,
//                     type: 'item',
//                     url: '/apps/contact/c-list',
//                     breadcrumbs: false
//                 }
//             ]
//         },
//         {
//             id: 'e-commerce',
//             title: <FormattedMessage id="e-commerce" />,
//             type: 'collapse',
//             icon: icons.IconBasket,
//             children: [
//                 {
//                     id: 'products',
//                     title: <FormattedMessage id="products" />,
//                     type: 'item',
//                     url: '/apps/e-commerce/products'
//                 },
//                 {
//                     id: 'product-details',
//                     title: <FormattedMessage id="product-details" />,
//                     type: 'item',
//                     url: '/apps/e-commerce/product-details/1',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'product-list',
//                     title: <FormattedMessage id="product-list" />,
//                     type: 'item',
//                     url: '/apps/e-commerce/product-list',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'checkout',
//                     title: <FormattedMessage id="checkout" />,
//                     type: 'item',
//                     url: '/apps/e-commerce/checkout'
//                 }
//             ]
//         }
//     ]
// };
const application: NavItemType = {
    id: 'application',
    // title: <FormattedMessage id="users" />,
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="User Management." />,
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/user/list',
            breadcrumbs: false
        },
        {
            id: 'plan',
            title: <FormattedMessage id="Subscription Plan" />,
            type: 'item',
            icon: icons.IconDeviceDesktopDollar,
            url: '/plan/list',
            breadcrumbs: false
        },
        // {
        //     id: 'ads',
        //     title: <FormattedMessage id="Ads" />,
        //     type: 'item',
        //     icon: icons.IconAd2,
        //     url: '/ads/list',
        //     breadcrumbs: false
        // },
        // {
        //     id: 'brands',
        //     title: <FormattedMessage id="Brands" />,
        //     type: 'item',
        //     icon: icons.IconBrandBumble,
        //     url: '/brands/list',
        //     breadcrumbs: false
        // },
        {
            id: 'teams',
            title: <FormattedMessage id="Teams" />,
            type: 'item',
            icon: icons.IconUsersGroup,
            url: '/teams/list',
            breadcrumbs: false
        },
        {
            id: 'folders',
            title: <FormattedMessage id="Folders" />,
            type: 'item',
            icon: icons.IconFolders,
            url: '/folders/list',
            breadcrumbs: false
        },
        {
            id: 'boards',
            title: <FormattedMessage id="Boards" />,
            type: 'item',
            icon: icons.IconClipboardText,
            url: '/boards/list',
            breadcrumbs: false
        },
        {
            id: 'termsAndCondition',
            title: <FormattedMessage id="Terms & Condition" />,
            type: 'item',
            icon: icons.IconChecklist,
            url: '/termsAndCondition/view',
            breadcrumbs: false
        },
        {
            id: 'privacyAndPolicy',
            title: <FormattedMessage id="Privacy And Policy." />,
            type: 'item',
            icon: icons.IconClipboardCopy,
            url: '/privacyAndPolicy/view',
            breadcrumbs: false
        }
    ]
};
export default application;
