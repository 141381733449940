import { createSlice } from '@reduxjs/toolkit';
import { TermsInitialStateType } from './types';

export const initialState: TermsInitialStateType = {
    error: null,
    terms: {},
    type: '',
    updatingTerms: false
};

const terms = createSlice({
    name: 'termsState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setTermsList(state, action) {
            state.terms = action.payload;
        },

        setType(state, action) {
            state.type = action.payload;
        },

        updateTermsPending(state) {
            state.updatingTerms = true;
            state.error = null;
        },
        updateTermsSuccess(state) {
            state.updatingTerms = false;
        },
        updateTermsFailure(state, action) {
            state.error = action.payload;
            state.updatingTerms = false;
        }
    }
});

export const { actions: TermsReducerAction, name: TermsReducerName, reducer: TermsReducer } = terms;
