import { createSlice } from '@reduxjs/toolkit';
import { PlanInitialStateType } from './types';

export const initialState: PlanInitialStateType = {
    error: null,
    plan: {},
    limit: 10,
    page: 1,
    singlePlan: {},
    updatingPlan: false
};

const user = createSlice({
    name: 'planState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setPlansList(state, action) {
            console.log('state, action', state, action);
            state.plan = action.payload;
        },

        setCurrentPage(state, action) {
            state.page = action.payload;
        },

        setLimit(state, action) {
            state.limit = action.payload;
        },

        setSinglePlan(state, action) {
            state.singlePlan = action.payload;
        },

        updatePlanPending(state) {
            state.updatingPlan = true;
            state.error = null;
        },
        updatePlanSuccess(state, action) {
            state.updatingPlan = false;
        },
        updatePlanFailure(state, action) {
            state.error = action.payload;
            state.updatingPlan = false;
        }
    }
});

export const { actions: PlanReducerAction, name: PlanReducerName, reducer: PlanReducer } = user;
