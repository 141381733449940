import { createSlice } from '@reduxjs/toolkit';
import { AccountStateType } from './types';

export const initialState: AccountStateType = {
    error: null,
    isLoggedIn: false,
    isLoading: false,
    userDetails: null
};

const account = createSlice({
    name: 'accountState',
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },

        setUserDetails(state, action) {
            state.userDetails = action.payload;
        },

        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        }
    }
});

export const { actions: AccountReducerAction, name: AccountReducerName, reducer: AccountReducer } = account;
