import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';

import { call, put, select } from 'redux-saga/effects';
import { planStateSelector } from './selector';
import { PlanReducerAction } from './slice';
import { createSinglePlan, getAllPlanService, getPlanById, updateSinglePlan } from 'utils/services/plan.service';

const saga = createSliceSaga({
    name: 'plan-state',
    caseSagas: {
        *getAllPlans() {
            try {
                yield put(PlanReducerAction.updatePlanPending());
                const { limit, page } = yield select(planStateSelector);
                const skip = page - 1;
                const response: AxiosResponse = yield call(getAllPlanService, {
                    limit,
                    skip
                });
                if (response?.status === 200) {
                    const plansList = response?.data?.data;
                    yield put(PlanReducerAction.setPlansList(plansList));
                    yield put(PlanReducerAction.updatePlanSuccess(response.data));
                } else {
                    yield put(PlanReducerAction.updatePlanFailure('Failed to get plan.'));
                }
            } catch (error) {
                console.log('error', error);
                yield put(PlanReducerAction.updatePlanFailure('Error get plan.'));
            }
        },

        *singlePlan(action: { payload: string }) {
            try {
                yield put(PlanReducerAction.updatePlanPending());
                const planId = action.payload;
                const response: AxiosResponse = yield call(getPlanById, { planId });
                console.log('response', response);
                if (response?.status === 200) {
                    const singlePlanData = response?.data?.data;
                    yield put(PlanReducerAction.setSinglePlan(singlePlanData));
                    yield put(PlanReducerAction.updatePlanSuccess(response.data));
                } else {
                    yield put(PlanReducerAction.updatePlanFailure('Failed to get plan.'));
                }
            } catch (error) {
                console.error('Error :', error);
                yield put(PlanReducerAction.updatePlanFailure('Error get plan.'));
            }
        },
        *createPlan(action: { payload: { values: any } }) {
            try {
                yield put(PlanReducerAction.updatePlanPending());
                const { values } = action.payload;
                const response: AxiosResponse = yield call(createSinglePlan, values);

                if (response?.status === 200) {
                    yield put(PlanReducerAction.updatePlanSuccess(response.data));
                    yield put(PlanSagaActions.getAllPlans());
                } else {
                    yield put(PlanReducerAction.updatePlanFailure('Failed to create plan.'));
                }
            } catch (error) {
                console.error('Error creating plan:', error);
                yield put(PlanReducerAction.updatePlanFailure(error.message || 'Error creating plan.'));
            }
        },
        *updatePlan(action: { payload: { id: string; values: any } }) {
            try {
                yield put(PlanReducerAction.updatePlanPending());

                const { id, values } = action.payload;
                const response: AxiosResponse = yield call(updateSinglePlan, id, values);

                if (response?.status === 200) {
                    yield put(PlanReducerAction.updatePlanSuccess(response.data));
                    yield put(PlanSagaActions.singlePlan(id));
                    yield put(PlanSagaActions.getAllPlans());
                } else {
                    yield put(PlanReducerAction.updatePlanFailure('Failed to update plan.'));
                }
            } catch (error) {
                console.error('Error updating plan:', error);
                yield put(PlanReducerAction.updatePlanFailure(error.message || 'Error updating plan.'));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: PlanSagaActions, saga: PlanSaga, name: PlanSagaName } = saga;
