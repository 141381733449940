import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.termsState || initialState;

export const termsSelector = createSelector(selectDomain, (state) => state.terms);

export const termsStateSelector = createSelector(selectDomain, (state) => state);

export const updatingTermsSelector = createSelector(selectDomain, (state) => state.updatingTerms);
