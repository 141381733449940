import { createAction } from '@reduxjs/toolkit';
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects';

const getType = (slice: any, actionKey: any) => {
    return `${slice}/${actionKey}`;
};

const getWatcherType = (sliceName: any, effectKey: any) => {
    return `${sliceName}/${effectKey}`;
};

const getWatcher = (sagaType: any, watcherType: any, effect: any) => {
    switch (sagaType) {
        case 'takeLatest':
            return function* () {
                yield takeLatest(watcherType, effect);
            };
        default:
            return function* () {
                yield takeEvery(watcherType, effect);
            };
    }
};

export type SliceEffect<A = any> = (args?: any) => A;
export type SliceEffects = {
    [Key: string]: SliceEffect<any>;
};

export function createSliceSaga(options: any) {
    const { caseSagas, name, sagaType } = options;

    const caseSagasNames = Object.keys(caseSagas);

    const actionCreators: any = {};
    const sagas: any = [];

    caseSagasNames.forEach((sagaName) => {
        const type = getType(name, sagaName); // ${slice}/${actionKey} == discovery-state/fetchDiscoveryList
        const currentCaseSaga = caseSagas[sagaName]; // saga generator function
        const watcherType = getWatcherType(name, sagaName); // ${sliceName}/${effectKey} == discovery-state/fetchDiscoveryList

        actionCreators[sagaName] = createAction(type);

        sagas.push(call(getWatcher('takeEvery', watcherType, currentCaseSaga)));
    });

    function* saga() {
        yield all(sagas);
    }

    return {
        saga,
        name,
        actions: actionCreators as any,
        sagaType,
        accessSaga: caseSagas
    };
}
