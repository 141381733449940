import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.boardState || initialState;

export const boardsSelector = createSelector(selectDomain, (state) => state.boards);

export const boardStateSelector = createSelector(selectDomain, (state) => state);

export const deletingBoardSelector = createSelector(selectDomain, (state) => state.deletingBoard);

export const updatingBoardSelector = createSelector(selectDomain, (state) => state.updatingBoard);

export const singleBoardSelector = createSelector(selectDomain, (state) => state.singleBoard);
