import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';
import { call, put, select } from 'redux-saga/effects';

import { DashboardReducerAction } from './slice';
import { dashboardStateSelector } from './selector';
import { getAllBrandsService, getDashboardDataService } from 'utils/services/dashboard.service';

const saga = createSliceSaga({
    name: 'dashboard-state',
    caseSagas: {
        *getAllBrands() {
            try {
                yield put(DashboardReducerAction.setIsLoading(true));
                const { limit } = yield select(dashboardStateSelector);
                const skip = 0;
                const response: AxiosResponse = yield call(getAllBrandsService, {
                    limit,
                    skip
                });
                if (response?.status === 200) {
                    yield put(DashboardReducerAction.setBrandsList([]));
                    yield put(DashboardReducerAction.setBrandsList(response?.data?.top10));
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                yield put(DashboardReducerAction.setIsLoading(false));
            }
        },
        *getDashboardData(action: { payload: string }) {
          try {
              yield put(DashboardReducerAction.setIsLoading(true));
              const response: AxiosResponse = yield call(getDashboardDataService, action.payload);
              if (response?.status === 200) {
                  yield put(DashboardReducerAction.setDashboardData({}));
                  yield put(DashboardReducerAction.setDashboardData(response?.data?.data));
              }
          } catch (error) {
              console.log('error', error);
          } finally {
              yield put(DashboardReducerAction.setIsLoading(false));
          }
      }
    },
    sagaType: 'takeEvery'
});

export const { actions: DashboardSagaActions, saga: DashboardSaga, name: DashboardSagaName } = saga;
