import React, { createContext, useEffect } from 'react';
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';
import axios from 'utils/axios';
import { KeyedObject } from 'types';
import { JWTContextType } from 'types/auth';
import { useDispatch } from 'react-redux';
import { AccountSagaActions } from 'store/account/sagas';
import { AccountReducerAction } from 'store/account/slice';

const chance = new Chance();

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);

    return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    dispatch(AccountReducerAction.setIsLoggedIn(true));
                } else {
                    dispatch(AccountReducerAction.setIsLoggedIn(false));
                }
            } catch (err) {
                console.error('err', err);
                dispatch(AccountReducerAction.setIsLoggedIn(false));
            }
        };

        init();
    }, [dispatch]);

    const login = async (email: string, password: string) => {
        await dispatch(
            AccountSagaActions.login({
                email,
                password
            })
        );
    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        dispatch(AccountSagaActions.logout());
    };

    const resetPassword = async (email: string) => {};

    const updateProfile = () => {};

    return <JWTContext.Provider value={{ login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
