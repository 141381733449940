import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.planState || initialState;

export const plansSelector = createSelector(selectDomain, (state) => state.plan);

export const planStateSelector = createSelector(selectDomain, (state) => state);

export const updatingPlanSelector = createSelector(selectDomain, (state) => state.updatingPlan);

export const singlePlanSelector = createSelector(selectDomain, (state) => state.singlePlan);
