import axiosServices from 'utils/axios';
import { foldersParams } from 'utils/types/folders.types';

export const getAllBoardsService = async (params: foldersParams) => {
    try {
        const response = await axiosServices.get(`/board/admin?limit=${params.limit}&skip=${params.skip}&search=${params.search}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const deleteBoardsService = async (params: any) => {
    try {
        const response = await axiosServices.delete(`/board/delete/${params.boardId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getBoardById = async (params: any) => {
    try {
        const response = await axiosServices.get(`/board/${params.boardId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createSingleBoard = async (values: any) => {
    try {
        const response = await axiosServices.post(`/board`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateSingleBoard = async (id: string, values: any) => {
    try {
        const response = await axiosServices.put(`/board/update/${id}`, values);
        return response;
    } catch (error) {
        return error;
    }
};
