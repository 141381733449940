import { createSlice } from '@reduxjs/toolkit';
import { TeamsInitialStateType } from './types';

export const initialState: TeamsInitialStateType = {
    error: null,
    teams: {},
    limit: 10,
    page: 1,
    search: '',
    usersList: [],
    singleTeam: {},
    deletingTeam: false,
    updatingTeam: false
};

const team = createSlice({
    name: 'teamState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setTeamsList(state, action) {
            state.teams = action.payload;
        },

        setUsersList(state, action) {
            state.usersList = action.payload;
        },

        setCurrentPage(state, action) {
            state.page = action.payload;
        },

        setLimit(state, action) {
            state.limit = action.payload;
        },

        setSearch(state, action) {
            state.search = action.payload;
        },

        setSingleTeam(state, action) {
            state.singleTeam = action.payload;
        },

        deleteTeamPending(state) {
            state.deletingTeam = true;
        },
        deleteTeamSuccess(state) {
            state.deletingTeam = false;
        },
        deleteTeamFailure(state) {
            state.deletingTeam = false;
        },
        updateTeamPending(state) {
            state.updatingTeam = true;
            state.error = null;
        },
        updateTeamSuccess(state, action) {
            state.updatingTeam = false;
        },
        updateTeamFailure(state, action) {
            state.error = action.payload;
            state.updatingTeam = false;
        }
    }
});

export const { actions: TeamReducerAction, name: TeamReducerName, reducer: TeamReducer } = team;
