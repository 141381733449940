import axiosServices from 'utils/axios';
import { userParams } from 'utils/types/user.types';

export const getAllUserService = async (params: userParams) => {
    console.log('params', params);
    try {
        const response = await axiosServices.get(`/users?limit=${params.limit}&skip=${params.skip}&search=${params.search}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const deleteUserService = async (params: any) => {
    try {
        const response = await axiosServices.delete(`/users/${params.userId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getUserById = async (params: any) => {
    try {
        const response = await axiosServices.get(`/users/${params.userId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createUserService = async (values: any) => {
    try {
        const response = await axiosServices.post(`/auth/signup`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateSingleUser = async (id: string, values: any) => {
    try {
        const response = await axiosServices.put(`/users/${id}`, values);
        return response;
    } catch (error) {
        return error;
    }
};
