import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';

import { call, put, select } from 'redux-saga/effects';
import { brandStateSelector } from './selector';
import { BrandReducerAction } from './slice';
import { createSingleBrand, deleteBrandService, getAllBrandsService, getBrandById, updateSingleBrand } from 'utils/services/brand.service';

const saga = createSliceSaga({
    name: 'brand-state',
    caseSagas: {
        *getAllBrand() {
            try {
                // yield put(BrandReducerAction.updateBrandPending());
                const { limit, page, search } = yield select(brandStateSelector);
                const skip = page - 1;
                const response: AxiosResponse = yield call(getAllBrandsService, {
                    limit,
                    skip,
                    search
                });
                if (response?.status === 200) {
                    // yield put(BrandReducerAction.updateBrandSuccess(response.data));
                    const updatedBrandList = {
                        data: [...response?.data?.data],
                        total: response?.data?.total
                    };
                    yield put(BrandReducerAction.setBrandsList(updatedBrandList));
                } else {
                    console.log('no response');
                    // yield put(BrandReducerAction.updateBrandFailure('Failed to Get Brand.'));
                }
            } catch (error) {
                console.log('error', error);
                // yield put(BrandReducerAction.updateBrandFailure('Failed to Get Brand.'));
            }
        },
        *deleteBrand(action: { payload: string }) {
            try {
                yield put(BrandReducerAction.deleteBrandPending());
                const brandId = action.payload;
                yield call(deleteBrandService, { brandId });
                yield put(BrandSagaActions.getAllBrand());
            } catch (error) {
                console.error('Error deleting Brand:', error);
                yield put(BrandReducerAction.deleteBrandFailure());
            } finally {
                yield put(BrandReducerAction.deleteBrandSuccess());
            }
        },
        *singleBrand(action: { payload: string }) {
            try {
                const brandId = action.payload;

                if (brandId) {
                    const response: AxiosResponse = yield call(getBrandById, { brandId });
                    if (response?.status === 200) {
                        const singleBrandData = {
                            data: response?.data?.data || {}
                        };

                        yield put(BrandReducerAction.setSingleBrand(singleBrandData));
                    }
                } else {
                    yield put(BrandReducerAction.setSingleBrand({}));
                }
            } catch (error) {
                console.error('Error Single Brand:', error);
            }
        },
        *createBrand(action: { payload: { values: any } }) {
            try {
                yield put(BrandReducerAction.updateBrandPending());

                const { values } = action.payload;
                const response: AxiosResponse = yield call(createSingleBrand, values);

                if (response?.status === 200) {
                    yield put(BrandReducerAction.updateBrandSuccess(response.data));
                } else {
                    yield put(BrandReducerAction.updateBrandFailure('Failed to Create Brand.'));
                }
            } catch (error) {
                console.error('Error Updating Brand:', error);
                yield put(BrandReducerAction.updateBrandFailure(error.message || 'Error Creating Brand.'));
            }
        },
        *updateBrand(action: { payload: { id: string; values: any } }) {
            try {
                yield put(BrandReducerAction.updateBrandPending());

                const { id, values } = action.payload;
                const response: AxiosResponse = yield call(updateSingleBrand, id, values);

                if (response?.status === 200) {
                    yield put(BrandReducerAction.updateBrandSuccess(response.data));
                    yield put(BrandSagaActions.singleBrand(id));
                } else {
                    yield put(BrandReducerAction.updateBrandFailure('Failed to update Brand.'));
                }
            } catch (error) {
                console.error('Error Updating Brand:', error);
                yield put(BrandReducerAction.updateBrandFailure(error.message || 'Error updating Brand.'));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: BrandSagaActions, saga: BrandSaga, name: BrandSagaName } = saga;
