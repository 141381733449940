import { createSlice } from '@reduxjs/toolkit';
import { FoldersInitialStateType } from './types';

export const initialState: FoldersInitialStateType = {
    error: null,
    folders: {},
    foldersList: {},
    limit: 10,
    page: 1,
    search: '',
    userId: '',
    singleFolder: {},
    deletingFolder: false,
    updatingFolder: false
};

const folder = createSlice({
    name: 'folderState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setFoldersList(state, action) {
            state.folders = action.payload;
        },

        setFoldersListDropdown(state, action) {
            state.foldersList = action.payload;
        },

        setCurrentPage(state, action) {
            state.page = action.payload;
        },

        setLimit(state, action) {
            state.limit = action.payload;
        },

        setSearch(state, action) {
            state.search = action.payload;
        },

        setSelectedUser(state, action) {
            state.userId = action.payload;
        },

        setSingleFolder(state, action) {
            state.singleFolder = action.payload;
        },

        deleteFolderPending(state) {
            state.deletingFolder = true;
        },
        deleteFolderSuccess(state) {
            state.deletingFolder = false;
        },
        deleteFolderFailure(state) {
            state.deletingFolder = false;
        },
        updateFolderPending(state) {
            state.updatingFolder = true;
            state.error = null;
        },
        updateFolderSuccess(state, action) {
            state.updatingFolder = false;
        },
        updateFolderFailure(state, action) {
            state.error = action.payload;
            state.updatingFolder = false;
        }
    }
});

export const { actions: FolderReducerAction, name: FolderReducerName, reducer: FolderReducer } = folder;
