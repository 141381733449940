import axiosServices from 'utils/axios';
import { planParams } from 'utils/types/plan.types';

export const getAllPlanService = async (params: planParams) => {
    console.log('params', params);
    try {
        const response = await axiosServices.get(`/plan?limit=${params.limit}&skip=${params.skip}`);
        console.log('response', response);
        return response;
    } catch (error) {
        return error;
    }
};

export const getPlanById = async (params: any) => {
    try {
        const response = await axiosServices.get(`/plan/details/${params.planId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createSinglePlan = async (values: any) => {
    try {
        const response = await axiosServices.post('/plan', values);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateSinglePlan = async (id: string, values: any) => {
    console.log('values', values);
    try {
        const response = await axiosServices.put(`/plan/${id}`, values);
        return response;
    } catch (error) {
        return error;
    }
};
