import { createSlice } from '@reduxjs/toolkit';
import { BrandType, DashboardInitialStateType } from './types';

export const initialState: DashboardInitialStateType = {
    brands: [] as BrandType[],
    dashboardData: {},
    limit: 50,
    isLoading: false
};

const dashboard = createSlice({
    name: 'dashboardState',
    initialState,
    reducers: {
        setBrandsList(state, action) {
            state.brands = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setDashboardData(state, action) {
            state.dashboardData = action.payload;
        }
    }
});

export const { actions: DashboardReducerAction, name: DashboardReducerName, reducer: DashboardReducer } = dashboard;
