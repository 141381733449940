import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';
import { call, put, select } from 'redux-saga/effects';
import { termsStateSelector } from './selector';
import { TermsReducerAction } from './slice';
import { getTermsAndCondition, updateTerms } from 'utils/services/termsAndCondition.service';

const saga = createSliceSaga({
    name: 'terms-state',
    caseSagas: {
        *getAllTerms() {
            try {
                const { type } = yield select(termsStateSelector);
                console.log('type', type);
                const response: AxiosResponse = yield call(getTermsAndCondition, {
                    type
                });
                if (response?.status === 200) {
                    const termsList = response?.data?.data;
                    yield put(TermsReducerAction.setTermsList(termsList));
                }
            } catch (error) {
                console.log('error', error);
            }
        },

        *updateTerms(action: { payload: { values: any } }) {
            try {
                yield put(TermsReducerAction.updateTermsPending());

                const { values } = action.payload;
                const response: AxiosResponse = yield call(updateTerms, values);

                if (response?.status === 200) {
                    yield put(TermsReducerAction.updateTermsSuccess(response.data));
                    yield put(TermsSagaActions.getAllTerms());
                } else {
                    yield put(TermsReducerAction.updateTermsFailure('Failed to update Terms.'));
                }
            } catch (error) {
                console.error('Error updating Terms:', error);
                yield put(TermsReducerAction.updateTermsFailure(error.message || 'Error updating Terms.'));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: TermsSagaActions, saga: TermsSaga, name: TermsSagaName } = saga;
