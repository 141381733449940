import axiosServices from "utils/axios";
import { brandParams } from "utils/types/brand.types";

export const getAllBrandsService = async (params: brandParams) => {
  try {
      const response = await axiosServices.get(`/brand/list/top-list?limit=${params.limit}&skip=${params.skip}`);
      return response;
  } catch (error) {
      return error;
  }
};

export const getDashboardDataService = async (year: string) => {
  try {
      const response = await axiosServices.get(`/dashboard?year=${year}`);
      return response;
  } catch (error) {
      return error;
  }
};
